import React, { useState, useEffect, useRef } from 'react';
import '../styles.css';

const LandingPage: React.FC = () => {
  const [showProducts, setShowProducts] = useState(false);
  const productSectionRef = useRef<HTMLDivElement>(null);
  const motto = 'Membangun Masa Depan dari Langkah Kecil';

  // Scroll listener to detect when the product section comes into view
  useEffect(() => {
    const handleScroll = () => {
      if (productSectionRef.current) {
        const rect = productSectionRef.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          setShowProducts(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-indigo-100 flex flex-col font-sans">
      {/* Hero Section */}
      <header className="hero-section flex items-center justify-center flex-col text-center text-indigo-700">
        <h1 className="text-6xl font-bold mb-4 fade-in-up">Ourdo</h1>
        <p className="text-2xl text-gray-600 fade-in-up">
          Building a future of{' '}
          <span className="text-indigo-500 font-semibold">collaboration</span>,{' '}
          <span className="text-indigo-500 font-semibold">learning</span>, and{' '}
          <span className="text-indigo-500 font-semibold">growth</span>.
        </p>
        <p className="text-lg text-indigo-500 italic font-medium mt-6 fade-in-up">
          {motto}
        </p>
      </header>

      {/* Product Cards */}
      <main
        ref={productSectionRef}
        className={`container mx-auto px-6 py-12 flex-grow transition-all duration-1000 ${
          showProducts ? 'opacity-100 slide-up' : 'opacity-0'
        }`}
      >
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            {
              title: 'Our Event',
              description: 'Your partner for organizing events with creativity and impact.',
              status: 'Launching Soon!',
            },
            {
              title: 'Our Learning',
              description: 'Empowering growth through hands-on education and training.',
              status: 'Launching Soon!',
            },
            {
              title: 'Our House',
              description: 'A collaborative space for innovation and teamwork.',
              status: 'Launching Soon!',
            },
          ].map((product, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-xl fade-in-up"
            >
              <div className="p-6">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">{product.title}</h2>
                <p className="text-gray-600 mb-6">{product.description}</p>
                <button className="bg-indigo-500 text-white py-2 px-4 rounded-full hover:bg-indigo-600">
                  Learn More
                </button>
              </div>
              <div className="bg-indigo-50 text-indigo-600 font-bold text-lg py-4 text-center">
                {product.status}
              </div>
            </div>
          ))}
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-indigo-700 text-white text-center py-6 fade-in-up">
        <p className="text-lg font-semibold">Are you ready to start your journey?</p>
        <button className="bg-yellow-400 text-indigo-800 py-2 px-6 rounded-full mt-4 hover:bg-yellow-300">
          Get Started
        </button>
      </footer>
    </div>
  );
};

export default LandingPage;
